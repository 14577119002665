import React, { useEffect, useState } from 'react';
import { Message } from '@taboset/api-interfaces';

export const App = () => {
  const [m, setMessage] = useState<Message>({ message: '' });

  useEffect(() => {
    fetch('/api')
      .then(r => r.json())
      .then(setMessage);
  }, []);

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <h1>Welcome to Taboset!</h1>
        <h2>Coming Soon</h2>
      </div>
      <div>{m.message}</div>
    </>
  );
};

export default App;
